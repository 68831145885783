import React from 'react';
import Footer from '../footer/footer';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
//import Select from "react-dropdown-select";
// import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react'
//import Image from '../../assets/img/cm1.png';
import InputGroup from 'react-bootstrap/InputGroup';
// import Toast from 'react-bootstrap/Toast';
import toast, { Toaster } from 'react-hot-toast';

import qrcode from '../../assets/img/qrcode.jpg'

// import '../../all.css';



export default function Register() {
   
    const [formValues, setFormValues] = useState([{ name: "", relation : ""}]);

    const [show, setShow] = useState(false);
    const [showsearch, setShowsearch] = useState(false);
    const [showguest, setShowguest] = useState(false);
    const [dispmember, Setdispmember] = useState(false);
    const [cntr, setCntr] = useState([]);
    const [name, setName] = useState();
    const [mail, setMail] = useState();
    const [iia, setIia] = useState();
    const [ofcenme, setOfcenme] = useState();
    const [ofcemail, setOfcemail] = useState();
    const [ph1, setPh1] = useState();
    const [transact, setTransact] = useState();
    const [cntrval, setCntrval] = useState();
    const [griia, setGriia] = useState();
    const [gname, setGname] = useState();
    const [amt, setAmt] = useState(2000);
    const [storyd, Setstoryd] = useState();
    //const [flag, SetFlag] = useState(0);
   
    const [getdtls, setGetdtls] = useState({
        name: "Member Name",
        mail_id: "Member mail",
        personal_mobile: "Member phone.no",
        events_id: "",
        eventjoined_id: "",
});

let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }

let addFormFields = () => {
    setFormValues([...formValues, { name: "", relation: "" }])
  }

let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
}

let handleSubmit = (event) => {
    event.preventDefault();
   console.log(JSON.stringify(formValues))

}
    //const [getmail, setGetmail] = useState();
    //const [getph, setGetph] = useState();

    //var val = 'Select Center';
 

    async function center() {
        try {
            const response = await axios.post("https://iiakottayam.com/iia-api/public/api/centerlist");
            setCntr(response.data.data);             
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        center();
    }, []);

    //console.log("location : "+state.post_id)
    // console.log(loc.state)
    /*cntr.map((c) => {
        let obj = { value: c.center, label: c.center };
        options.push(obj);
    })*/
    /*const options = [
        { value: 'Kottayam', label: 'Kottayam' },
        { value: 'Ernakulam', label: 'Ernakulam' },
        { value: 'Alappuzha', label: 'Alappuzha' }
    ]*/

    function submit(e) {
        e.preventDefault();
        console.log(JSON.stringify(formValues))
        if (!name || !mail || !iia || !ph1 || !cntrval || !transact) {
        
            toast.error("Please Fill in the Details")
        } else {
            let formData = new FormData();

          // console.log(amt);
            if( !formValues[0].name){
                
            }else{
                formData.append("dtls", JSON.stringify(formValues))
            }
            formData.append("event_id", 4);
            formData.append("name", name);
            formData.append("iia", iia);
            formData.append("mail", mail);
            formData.append("office_nme", ofcenme);
            formData.append("amount", amt);
            formData.append("phone1", ph1);
            formData.append("transaction_id", transact);
            formData.append("center_id", cntrval);
            formData.append("story", storyd);
            
            

            axios.post('https://iiakottayam.com/iia-api/public/api/evntjndmmbrinsrt', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (response) {
                    console.log(response.data.data[0]['status']);
                    if((response.data.data[0]['member_status'] == 'success' && response.data.data[0]['guest_status'] == 'success') || (response.data.data[0]['member_status'] == 'success' && response.data.data[0]['guest_status'] == 'nill')) {
                        toast.success('Successfully Registered!')
                    } else {
                        toast.error("Please Fill in the Details")
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
                setName();
                setMail();
                setIia();
                setPh1();
                setTransact();
                setCntrval();
                setFormValues([{ name: "", relation : ""}])
                // setAmt()
            e.preventDefault();
            e.target.reset();
        }
    }


    function getwithiia(e) {
        e.preventDefault();
        if (!griia) {
            setShowsearch(true);
           
        } else {
            let formData = new FormData();

            //Adding files to the formdata
            formData.append("iia_no", griia);

            axios.post('https://iiakottayam.com/iia-api/public/api/getwithiiano', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (response) {
                    //console.log(response.data.data);
                    //setLiv(response.data.data);     
                    Setdispmember(true);               
                    if (response.data.data[0] == "not found") {
                        setShowsearch(true);

                    } else {
                        setGetdtls(response.data.data[0]);
                    }

                

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    // const [formValues, setFormValues] = useState([{ name: "", realtionship: "" }])

    // let handleChange = (i, e) => {
    //     let newFormValues = [...formValues];

    //     newFormValues[i][e.target.name] = e.target.value;
    //     setFormValues(newFormValues);
    // }

    // let addFormFields = () => {
    //     setFormValues([...formValues, { name: "", realtionship: "" }])
    // }

    // let removeFormFields = (i) => {
    //     let newFormValues = [...formValues];
    //     newFormValues.splice(i, 1);
    //     setFormValues(newFormValues)
    // }

    // let handleSubmit = (event) => {
        
    //     event.preventDefault();
    //     Setdispmember(true);
    //     if (formValues[0].name == "" || formValues[0].name == " " || formValues[0].realtionship == "" || formValues[0].realtionship == " ") {
    //         setShowguest(true);
            
           
    //     } else {
          
    //         let formData = new FormData();

         
    //         formData.append("evnid", getdtls.events_id);
    //         formData.append("jndid", getdtls.eventjoined_id);
    //         formData.append("amount", amt);
    //         formData.append("guests", JSON.stringify(formValues));

    //         axios.post('https://iiakottayam.com/iia-api/public/api/guestsinsert', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         })
    //             .then(function (response) {
                
    //                 setGetdtls(response.data.data[0]);
                   
    //                 if(response.status == 200) {
                        
    //                 } else {
                        
    //                 }
                   
                  

    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    //         event.preventDefault();
    //         event.target.reset();
    //     }

    // }


    useEffect(() => {
        // 👇 scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        !formValues[0].name ? setAmt(2000)  : setAmt(2000 * (formValues.length + 1)+500)
    }, [formValues]);



    return (
        // <Box pt={{ base: "130px", md: "80px", xl: "80px" }}> 
            
        <div>
            <InnerNavbar />
            <div className='about-banner container-fluid'>
                <div className='banner-bg register-bg'>
                    <h1>Register</h1>
                </div>
            </div>
            <div className='event-register-main container-fluid'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className='member-box'>
                                <h4>Already an IIA Member?</h4>
                                            <p>Please fill the details for Kerala Chapter Convention 2022</p>
                                            <Form onSubmit={submit} className='row'>
                                                <div className='col-12'>
                                                    <label>Your Name <span className="h4 text-danger">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Your name"
                                                        onChange={e => setName(e.target.value)}
                                                    />
                                    </div>
                                    <div className='col-12'>
                                                    <label>IIA Number <span className="h4 text-danger">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="IIA Number"
                                                        onChange={e => setIia(e.target.value)}
                                                    />
                                    </div>
                                    <div className='col-12'>
                                                    <label>Your Email <span className="h4 text-danger">*</span></label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Your Email"
                                                        onChange={e => setMail(e.target.value)}
                                                    />
                                    </div>
                                    <div className='col-12'>
                                                    <label>Your Phone Number <span className="h4 text-danger">*</span></label>
                                                    <Form.Control
                                                        type="tel"
                                                        maxLength={13}
                                                        placeholder="Your Phone Number"
                                                        onChange={e => setPh1(e.target.value)}
                                                    />
                                    </div>
                                   
                                    <div className='col-12'>
                                                    <label>Office Name </label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Office Name"
                                                        onChange={e => setOfcenme(e.target.value)}
                                                    />
                                    </div>
                                    {/* <div className='col-12'>
                                                    <label>Office Email </label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Office Email"
                                                        onChange={e => setOfcemail(e.target.value)}
                                                    />
                                    </div> */}
                                    <div className='col-12'>
                                                    <label>Center Name <span className="h4 text-danger">*</span></label>
                                        {/*<Select options={options} onChange={(values) => this.onChange(values)} />*/}
                                        <Form.Select onChange={e => setCntrval(e.target.value)}>
                                            <option>Sellect Center</option>
                                            {cntr.map((c) => <option value={c.center_id} > { c.center }</option>)}                                           
                                        </Form.Select>
                                    </div>
                                    <div className='col-12'>
                                    {formValues.map((element, index) => (
            <div className="form-inline form-b" key={index}>
              <label>If your spouse accompany with you</label>
              <Form.Control type="text" name="name" placeholder="Name" value={element.name || ""} onChange={e => handleChange(index, e)} />
          
              <input type="hidden" name="relation"  value={element.relation || ""} onChange={e => handleChange(index, e)} />
              {
                index ? 
                //   <button type="button"  className="button remove" onClick={() => removeFormFields(index)}>Remove</button> 
                null
                : null
              }
            </div>
          ))}
             {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                                    </div>
                                    <div className='col-12'>
                                                    <label>Total Amount </label>
                                                    <Form.Control className='amount-box'
                                                
                                                    disabled
                                                        type="text"
                                                        placeholder=""
                                                        value = {amt}
                                                        //onChange={e => setAmt(e.target.value)}
                                                    />
                                    </div>
                                    <div className='col-12'>
                                                    <label>Transaction Id <span className="h4 text-danger">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        //maxLength={13}
                                                        placeholder="After the payment you will get transaction id"
                                                        onChange={e => setTransact(e.target.value)}
                                                    />
                                                  
                                    </div>
                                    <div className='col-12'>
                                            <div className='form-b'>
                                                    <label>En Story ( max 250 words )</label>
                                                    <textarea
                                                        type="text"
                                                        rows={4}
                                                        placeholder="We all have a story to tell. The untold story about 'your' practice. The ups and downs and the big leap.Pen it  down in less than 200 words, or ping us with a simple ‘abcd’. 
                                                        We will get back to you.. Let’s begin with your story."
                                                        onChange={e => Setstoryd(e.target.value)}
                                                    />
                                            </div>        
                                    </div>
                                    <div className='col-12'>
                                    {!name || !mail || !iia || !ph1 || !cntrval || !transact ? '' : <Button type="submit" >Register</Button>}
                                        

                                    </div>
                                    <div className='col-12'>
                                        {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                            <Toast.Header>
                                                <strong className="text-danger me-auto">Caution</strong>
                                            </Toast.Header>
                                            <Toast.Body className="text-danger">Check for empty or invalid inputs</Toast.Body>
                                        </Toast> */}
                                    </div>
                                </Form>
                                {/* <p>Not an IIA Member? <Link to="/memberregistration">Register Now</Link></p> */}
                            </div>
                        </div>














{/* guest section ==================================================================================== */}
                        {/* <div className='col-md-2 col-12 m-auto'>
                            <div className='or-div'>
                                            <p>OR</p>
                            </div>
                        </div> */}
                        <div className='col-md-6 col-12'>
                            <div className='guest-register-box'>

                            <h4 className='text-center'>Pay Now</h4>
                            <p className='text-center mb-3'>Registration fees <span className='amount'>{2000}</span> (Early Bird...)</p>
                            {/* <h6 className='text-center mb-3 h5'>Rs :{loc.state.amount}</h6> */}
                            <p className='mb-1 text-center'>AC/Name : THE ARCHITECTS GROUP KOTTAYAM</p>
                               <p className='text-center mb-1'> Ac. No : 004303363884190001</p>
                                <p className='text-center mb-1'>IFSC CODE : CSBK0000043</p>
                                <p className='text-center mb-1'>Bank name : Catholic syrian bank.</p>
                                <img className='qrcodedata' src={qrcode}></img>
                                {/* <h4>Register as Guest</h4>
                                <p>Please fill the details to register as a guest for this event</p>

                                <form onSubmit={handleSubmit}>
                                    <label>IIA Number</label>
                                    <InputGroup >

                                        <Form.Control type="text"
                                            placeholder="IIA Number"
                                            aria-label="IIA Number"
                                            aria-describedby="basic-addon2"
                                            onChange={e => setGriia(e.target.value)}
                                        />
                                        <Button onClick={getwithiia} variant="outline-secondary" className="button-addon2">
                                            Search
                                        </Button>
                                    </InputGroup>                                    
                                    <div className='row r-user'>


                                        
                                        <div className='col-md-5 col-12'>
                                            <img src={Image} alt="this was commented" />
                                        </div>



                                        <div className='col-12'>
                                            <Toast onClose={() => setShowsearch(false)} show={showsearch} delay={3000} autohide>
                                                <Toast.Header>
                                                    <strong className="text-danger me-auto">Caution</strong>
                                                </Toast.Header>
                                                <Toast.Body className="text-danger">IIA number not found</Toast.Body>
                                            </Toast>
                                        </div>
                                        <div className='col-12' style={{display: dispmember ? 'block':'none'}}>
                                            <h5>Name :{getdtls.name}</h5>
                                            <p className='mb-0'>Email Id :{getdtls.mail_id}</p>
                                            <p>Phone Number :{getdtls.personal_mobile}</p>
                                        </div>

                                    </div>
                                    {formValues.map((element, index) => (
                                        <div className="form-inline form-b" key={index}>
                                            <label>Name <span className="h4 text-danger">*</span></label>
                                            <InputGroup >
                                                <input type="text" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} />
                                            </InputGroup>
                                            <label>Realtionship <span className="h4 text-danger">*</span></label>
                                            <InputGroup>
                                                <input type="text" name="realtionship" value={element.realtionship || ""} onChange={e => handleChange(index, e)} />
                                            </InputGroup>
                                            {
                                                index ?
                                                    <button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button>
                                                    : null
                                            }
                                        </div>
                                    ))}
                                    <div className="button-section">
                                        <button className="button add" type="button" onClick={() => addFormFields()}>Add</button>
                                        <button className="button submit" type="submit">Submit</button>

                                     
                                    </div>
                                    <div className='col-12'>
                                        <Toast onClose={() => setShowguest(false)} show={showguest} delay={3000} autohide>
                                            <Toast.Header>
                                                <strong className="text-danger me-auto">Caution</strong>
                                            </Toast.Header>
                                            <Toast.Body className="text-danger">Check for empty or invalid inputs</Toast.Body>
                                        </Toast>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Toaster position='top-right' />
                    </div>
               
        // </Box>
    )
}