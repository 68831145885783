import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Footer from '../footer/footer';
import InnerNavbar from '../inner-navbar/inner-navbar';
import { Toaster, toast } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import qrcode from '../../assets/img/qrcode.jpg';

export default function EventRegisterNewMember() {
    const location = useLocation();
    const { post_id, amount } = location.state || {}; 

    const [formData, setFormData] = useState({
        name: '',
        photo: null,
        email: '',
        phone: '',
        iiaNumber: '',
        iiaCentre: '',
        coaNumber: '',
        officeAddress: '',
        soapBox: 'No',
        transactionId: '',
        spouseAccompany: 'No',
        spouseName: '',
        events_id: post_id || "",
    });

    const [memberprice, setMemberPrice] = useState(1199);
    const [cntr, setCntr] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // New state for loading

    useEffect(() => {
        async function fetchCenters() {
            try {
                const response = await axios.post("https://iiakottayam.com/iia-api/public/api/centerlist");
                setCntr(response.data.data);
            } catch (error) {
                console.error("Error fetching centers:", error);
            }
        }
        fetchCenters();
    }, []);

    useEffect(() => {
        if (post_id) {
            setFormData(prevState => ({ ...prevState, event_id: post_id }));
        }
    }, [post_id]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'spouseAccompany') {
            const newPrice = value === 'Yes' ? 1199 + 1199 : 1199;
            setMemberPrice(newPrice);
            console.log("Updated Price:", newPrice);
        }
    };

    const handleFileChange = (event) => {
        setFormData(prevState => ({
            ...prevState,
            photo: event.target.files[0]
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading to true

        if (!formData.iiaNumber.trim()) {
            toast.error('IIA Number is required!');
            setIsLoading(false); // Reset loading state
            return;
        }

        if (!formData.transactionId.trim()) {
            toast.error('Transaction ID is required!');
            setIsLoading(false); // Reset loading state
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append("event_id", formData.events_id);
        formDataToSend.append("name", formData.name);
        formDataToSend.append("iia_no", formData.iiaNumber);
        formDataToSend.append("mail_id", formData.email);
        formDataToSend.append("office_name", formData.officeAddress);
        formDataToSend.append("personal_mobile", formData.phone);
        formDataToSend.append("photo", formData.photo || ""); 
        formDataToSend.append("centerlist_id", formData.iiaCentre);
        formDataToSend.append("transaction_id", formData.transactionId);
        formDataToSend.append("amount", memberprice);
        formDataToSend.append("payment_status", 0);
        formDataToSend.append("coa_number", formData.coaNumber);
        formDataToSend.append("soap_box", formData.soapBox);
        formDataToSend.append("spouse_accompany", formData.spouseAccompany);
        formDataToSend.append("spouse_name", formData.spouseName);

        try {
            await axios.post(
                'https://iiakottayam.com/iia-api/public/api/evntjndmmbrNewinsrt',
                formDataToSend,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            toast.success('Registration successful!');
            setFormData({ // Reset form data after successful submission
                name: '',
                photo: null,
                email: '',
                phone: '',
                iiaNumber: '',
                iiaCentre: '',
                coaNumber: '',
                officeAddress: '',
                soapBox: 'No',
                transactionId: '',
                spouseAccompany: 'No',
                spouseName: '',
                events_id: post_id || "",
            });
        } catch (error) {
            console.error("API Error:", error);
            toast.error('Registration failed. Please try again.');
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    return (
        <div>
            <InnerNavbar />
            <div className="event-register-main mt-5 container-fluid">
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='member-box'>
                                <h4 className="text-center">Event Registration</h4>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Photo</Form.Label>
                                        <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>IIA Number</Form.Label>
                                        <Form.Control type="text" name="iiaNumber" value={formData.iiaNumber} onChange={handleChange} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>COA Number</Form.Label>
                                        <Form.Control type="text" name="coaNumber" value={formData.coaNumber} onChange={handleChange} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>IIA Centre</Form.Label>
                                        <Form.Select name="iiaCentre" value={formData.iiaCentre} onChange={handleChange} required>
                                            <option value="">Select Center</option>
                                            {cntr.map((c) => (
                                                <option key={c.center_id} value={c.center_id}>
                                                    {c.center}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Soap Box Registration</Form.Label>
                                        <Form.Select name="soapBox" value={formData.soapBox} onChange={handleChange}>
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Transaction ID <span className='mob'>(Payment Details Given Below)</span> </Form.Label>
                                        <Form.Control type="text" name="transactionId" value={formData.transactionId} onChange={handleChange} required />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Spouse Accompanying?</Form.Label>
                                        <Form.Select name="spouseAccompany" value={formData.spouseAccompany} onChange={handleChange}>
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>
                                        </Form.Select>
                                    </Form.Group>

                                    {formData.spouseAccompany === "Yes" && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Spouse Name</Form.Label>
                                            <Form.Control type="text" name="spouseName" value={formData.spouseName} onChange={handleChange} />
                                        </Form.Group>
                                    )}

                                    <h5 className="text-center mt-3">Total Price: <span>{memberprice}</span></h5>

                                    <div className="text-center">
                                        <Button variant="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? 'Loading...' : 'Register'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='guest-register-box'>
                                <h4 className='text-center'>Pay Now</h4>
                                <p className='text-center mb-3'>Registration fees: <span>{memberprice}</span></p>
                                <p className='mb-1 text-center'>AC/Name : The Indian Institute of Architects Kottayam Centre</p>
                                <p className='text-center mb-1'>Ac. No :  10250200112653</p>
                                <p className='text-center mb-1'>IFSC CODE : FDRL0001025</p>
                                <p className='text-center mb-1'>SWIFT Code : FDRLINBBIBD</p>
                                <p className='text-center mb-1'>Bank name : Federal Bank, Kottayam</p>
                                <img className='qrcodedata' src={qrcode} alt="QR Code" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Toaster position="top-right" />
        </div>
    );
}