import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Footer from '../footer/footer';
import { useEffect } from 'react';


export default function TermsAndConditions() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

  return (
    <div>
        <InnerNavbar/>
        <div className='member-banner container-fluid'>
          <div className='banner-bg'>
            <h1>Terms and Conditions</h1>
          </div>
        </div>
        <div className='terms-main container-fluid'>
            <div className='custom-container'>
              <h4>1. Acceptance of this Agreement</h4>
              <p>1.1 By clicking on the ‘SUBMIT’ button, the applicant agrees to the terms and conditions, compulsions, representations, warranties and agreements contained herein (the “Agreement”). In the occasion, You are not keen to accept the Agreement, You shall not be approved or permitted to continue further to view or use in any mode any content, information, courseware, products and services (“Services”) published, available or provided on https://indianinstituteofarchitects.com(the “Website”), which is possessed, preserved and observed by The Indian Institute of Architects (IIA) (“Us”, “We” or “Our”).</p>
              <h4>2. User ID and Password</h4>
              <p>2.1 By entering into this Agreement, You admit and agree that your user ID and password (“Participant Account”) is for your private utilization only. Use or distribution of Your contestant Account with an additional user is not allowed and is basis for instant blocking of Your right to use to the Website, the Services and the Content and Courseware and termination of this Agreement.</p>
              <p>2.2 You consent that you are exclusively accountable for upholding the privacy of Your member Account and for all actions that happen under it. You consent to instantly inform our customer support team at iiapublication@gmail.com if you become aware of or have reason to believe that there is any unauthorized use of your member Account. You also agree to take all logical steps to stop such illegal use and to assist with us in any inquiry of such unlawful uses. We shall not beneath any conditions be held accountable for any claims connected to the use or maltreatment of Your member Account due to the activities of any 3rd party outside of our control or due to Your failure to uphold the privacy and safety of Your member Account. </p>
              <h4>3. Content and Courseware</h4>
              <p>3.1 As a part of our Services provided through our Website, We shall permit you access to our content, forms, other information, documents, data which may be in audio/video, written, graphic, recorded, photographic or any machine-readable design.</p>
              <h4>4. Usage of the Website and Services</h4>
              <p>4.1 We award you an individual, limited, non-transferable, non-exclusive and revocable permit to use the Website, the Services and the Content and Courseware presented through the Website till the time the achievement of the certification training course that you have sign up for or termination of this Agreement according to the T&C set forth herein, either is earlier. The Services and the Content and Courseware are offered exclusively for your individual and non-commercial utilization to aid you in completing the certification training course you have listed for (“Restricted Purpose”).</p>
              <p>4.2 You are granted online access to the Website, the Services and the Content and may download, save, or print the Content solely for the Restricted Purpose.</p>
              <p>4.3 You are not permitted to reproduce, broadcast, distribute, sub-license, broadcast, disseminate or organize derivative works of the Content , or any part thereof, in any way or through any communication channels or means, for any reason other than the constrained Purpose, without our former written consent</p>
              <h4>5. Intellectual Property Rights</h4>
              <p>5.1 Although You are settled a restricted and non-exclusive right to use the Website, the Services and the Content for the limited Purpose as set forth in this Agreement, You admit and consent that We are the only and restricted holder of the Website, the Services and the Content and as such are vested with all intellectual property rights and other ownership rights in the Website, the Services and the Content.</p>
              <p>5.2 You admit and agree that this contract other than authorizing you to use the Website, the Services and the Content for the limited reason does not express to You in any way or form any right, title or concern of a proprietary or any other nature in the Website, the Services and the Content.</p>
              <h4>6. Usage of Personal Information of Participants</h4>
              <p>6.1 We set aside the right to mark your image in any photos, videos, or other promotional substance used by Us. Further, we may use your private information to notify you about other certification training courses accessible by us. However, We shall not issue or lend or share your personal information with any 3rd party advertising database or reveal your personal information to any 3rd party apart from on a case-to-case basis after appropriate confirmation of such 3rd party or if necessary under any appropriate law.</p>
              <h4>7. Limitation of Liability</h4>
              <p>7.1 You specifically concur that use of the Website, the Services and the Content are at your only threat. We do not guarantee that the Website or the Services or admittance to the Content will be continuous or fault free; nor is there any assurance as to the results that may be attained from the utilization of the Website, the Services or the Content or as to the accurateness or consistency of any information offered through the Website, the Services or the Content. In no occasion will we or any individual or creature involved in generating, producing or sharing out the Website, the Services or the Content be accountable for any direct, indirect, incidental, exceptional or significant damages happening out of the use of or incapacity to use the Website, the Services or the Content.</p>
              <p>7.2 The disclaimer of responsibility enclosed during this clause relates to any or all loss or harm caused by any collapse of presentation, mistake, omission, disruption, deletion, defect, postponement operation or diffusion, computer program, communiqué line failure, thievery or demolition or unauthorized access to, modification of, or use of records or the other material, whether or not for breach of contract, inattention, or underneath the other reason behind action.</p>
              <p>7.3 You herewith purposely accept that we don’t seem to be accountable for any slanderous, offensive, wrongful, or ineligible conduct of 3rd parties, or different users of the web site, the Services or the Content which the danger of harm or injury from the preceding rests completely with every user.</p>
              <p>7.4 You agree that our responsibility or the liability of our associates, directors, officers, human resources, negotiators and licensors, if any, occurring out of any reasonably officially authorized claim (whether in contract, wrongdoing or else) in any manner associated with the Services or the Content shall not go beyond the fee you paid to us for the actual certification coaching course.</p>
              <h4>8. Term and Termination</h4>
              <p>8.1 This Agreement can become efficient upon Your approval of the terms of this Agreement by Your clicking on the “submit” button and, subject to the T&C of this Agreement, can stay in impact until You uphold a current, absolutely paid up on-line member Account, or till terminated by us, whichever is earlier.</p>
              <p>8.2 we preserve the proper to finish this Agreement and block Your entrance to the Content with instant impact by causing a written notice via email to You to the present impact (“Immediate Termination Date”), if such termination is formed as a results of Your deceit, default, misconduct or breach of Your responsibilities associated with or underneath this Agreement (“Event of Default”). On the prevalence of any Event of Default, we shall be certified to exercise all the rights and remedies underneath this Agreement or relevant Law or out there in equity to hunt indemnification for any Loss or Claim ensuing from any such Event of Default.</p>
              <p>8.3 The conditions of clause four.3, clause 7.2, clause eight and clause eleven of this Agreement shall survive the termination of this Agreement.</p>
              <h4>9. Indemnity</h4>
              <p>9.1 You comply with indemnify and hold us, Our contractors, licensors, directors, officers, workers and agents, safe from and against any or all claims, losses, indemnity, liabilities and expenses as well as attorneys’ fees, happening out of Your unauthorized use of the web site, the Services and therefore the Content or any disobedience or breach of this Agreement or any provisions hereof.</p>
              <h4>10. Waiver</h4>
              <p>10.1 Neither breakdown nor holdup on the a part of any party to exercise any right, remedy, power or priority hereafter shall function as a release there from, or of the exercise of the other right, remedy, power or privilege. No term of this Agreement shall be considered ignored, and no breach approval to, unless such release or consent shall be in lettering and sign by the party alleged to own waived or permitted. No release of any rights or approval to any breaches shall represent a release of the other rights or consent to the other breach</p>
              <h4>11. Severability</h4>
              <p>11.1 Within the occasion any provision of this Agreement is control illogical or unenforceable underneath the applicable laws of Republic of India, the remaining provisions shall continue fully force and impact, and therefore the Agreement shall be deemed to be transformed by replacement such invalid or unenforceable provision with a legitimate and enforceable provision that offers impact as closely as doable to the intentions of the parties as articulated by the invalid or unenforceable provision.</p>
              <h4>12. Governing Law and Jurisdiction</h4>
              <p>This Agreement shall be ruled by and construed in accordance with the Laws of Republic of India, Republic of India shall have the exclusive jurisdiction over any matter with reference to, in reference to, or arising out of, this Agreement.</p>
              <h4>13. Change and Assignment</h4>
              <p>13.1 We reserve the proper to unilaterally adjust or modify this Agreement while not giving any previous notification to You. We shall but issue the revised agreement on the web site so you’re tuned in to the revisions, alterations and amendments created by us to the present Agreement. You acknowledge and agree that it’s your accountability to see the web site sporadically for any revisions, modifications and amendments. Your continuous use of or access to the web site, the Services and therefore the Content following the posting of any changes to the present Agreement shall represent acceptance of these changes.</p>
              <p>13.2 You’re not permissible to assign this Agreement or the rights and responsibilities mentioned during this Agreement to any 3rd party and you merely shall be control accountable for any breach of this Agreement or any terms and conditions hereof.</p>
              <h4>14. Entire Agreement</h4>
              <p>14.1 This Agreement, in conjunction with the privacy policy, refund policy, rescheduling policy, terms of use and any further tips, rules and/or disclaimers announce on the web site constitutes the whole agreement governing Your use of our web site and supersedes any previous agreements, if any, with reference to any matter restrained during this Agreement</p>
              <h4>15. Refund/Cancellation Policies</h4>
              <p>15. Application fee is nonrefundable and There shall be no cancellation applicable.</p>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
