import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import Footer from "../footer/footer";
import InnerNavbar from "../inner-navbar/inner-navbar";
import { Toaster } from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

export default function EventRegisterNew() {
  const location = useLocation();
  const eventId = location.state?.post_id || null;
  const amount = location.state?.amount || 0;

  useEffect(() => {
    console.log("Received post_id from previous page:", eventId); // Debugging log
  }, [eventId]);

  return (
    <div>
      <InnerNavbar />
      <div className="container mt-5 event-register-new">
        <Row className="justify-content-center">
          {/* IIA Member Registration */}
          <Col md={6}>
            <Card className="p-4 reg-card">
              <h3>Are you an IIA Member?</h3>
              <p>If you are a member, please register through the Member Registration portal.</p>
              <Button as={Link} to="/eventregisternewmember" state={{ post_id: eventId, amount: amount }}>
                Member Registration
              </Button>
            </Card>
          </Col>

          {/* Guest Registration */}
          <Col md={6}>
            <Card className="p-4 reg-card">
              <h3>Guest Registration</h3>
              <p>If you are not an IIA member, proceed with guest registration.</p>
              <Button as={Link} to="/guest-registration" state={{ post_id: eventId, amount: amount }}>
                Guest Registration
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
      <Toaster position="top-right" />
    </div>
  );
}
