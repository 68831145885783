import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

// ✅ Corrected imports
import Home from './components/home/home';
import MainAbout from './components/about/about';
import MainEvents from './components/events/events';
import MainEventsDetails from './components/events-detail/events-detail';
import MembersMain from './components/members/members';
import GalleryMain from './components/gallery/gallery';
import GalleryDetailMain from './components/gallery-detail/gallery-detail';
import ContactMain from './components/contact/contact';
import MemberRegistation from './components/member-registration/member-registration';
import EventRegister from './components/event-register/event-register';
import Register from './components/event-register/register';
import StudentRegistation from './components/student-register/student-registration';
import TermsAndConditions from './components/terms-and-conditions/terms-and-conditions';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import RefundPolicy from './components/refund-policy/refund-policy';
import EventRegisterNew from './components/event-register/event-register-new';
import EventRegisterNewMember from './components/event-register/event-register-member';
import EventRegisterNewGuest from './components/event-register/event-register-guest';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<MainAbout />} />
          <Route path="/events" element={<MainEvents />} />
          <Route path="/eventsdetail" element={<MainEventsDetails />} />
          <Route path="/members" element={<MembersMain />} />
          <Route path="/gallery" element={<GalleryMain />} />
          <Route path="/gallerydetail" element={<GalleryDetailMain />} />
          <Route path="/contact" element={<ContactMain />} />
          <Route path="/memberregistration" element={<MemberRegistation />} />
          <Route path="/eventregister" element={<EventRegister />} />
          <Route path="/register" element={<Register />} />
          <Route path="/studentregister" element={<StudentRegistation />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/eventregisternew" element={<EventRegisterNew />} />
          <Route path="/eventregisternewmember" element={<EventRegisterNewMember />} />
          <Route path="/guest-registration" element={<EventRegisterNewGuest />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
