import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SliderImgOne from "../../assets/img/slider1.jpg";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

export default function HomeSlider(props) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="home-slider">
        <img
          src={
            props.banner === "nill"
              ? null
              : "https://iiakottayam.com/iia-api/public/banner/" +
                props.banner[0].bannerimg
          }
          alt="Banner"
        />
        <div className="reg-btn">
          <button
            onClick={() =>
              navigate("/eventregisternew", {
                state: {
                  post_id: props.latestevnt?.events_id || null,
                  amount: props.latestevnt?.events_amount || 0,
                },
              })
            }
          >
            Register Now
          </button>
        </div>
        <div className="social-media-icons">
          <Link to="#">
            <FaFacebookF />
          </Link>
          <Link to="#">
            <FaInstagram />
          </Link>
          <Link to="#">
            <FaLinkedinIn />
          </Link>
        </div>
      </div>
    </div>
  );
}
